<template>
    <lay-row space="10">
        <lay-col md="8">
            <lay-panel style="padding: 10px"> 
                    <lay-table 
                        :page="payInfoPage" 
                        :height="'100%'"
                        :columns="columns" 
                        :loading="loading"
                        :default-toolbar="false"
                        :data-source="payIndataSource" 
                        v-model:selected-keys="selectedKeys"  
                        @change="payInfoChange"
                        @sortChange="sortChange"> 
                        <template v-slot:toolbar>
                          <div style="  display: flex;      align-items: center;">  
                            <div style="margin-right: auto; "> <lay-button type="primary" @click="addPayInfos">添加</lay-button></div>
                            <div style="  text-align: center; ">
                                <h1>支付信息列表</h1>
                            </div>
                        </div> 
                        </template> 
                        <template v-slot:operator="{ row }"> 
                        <lay-button size="xs" @click="payInfoRemove(row)">删除</lay-button>
                        </template>
                        <template #status="{ row }">
                            <lay-switch  :model-value="row.state == 0 ? true : false" @change="payInfoChangeStatus($event , row)"></lay-switch>
                            </template>
                    </lay-table> 
            </lay-panel>
 
            <lay-layer v-model="isPayInfo" :shade="false" :area="['350px', '300px']" :btn="payInfoBtn" title="添加支付信息">
                <div style="padding: 20px;">
                <lay-form :model="payInfo"   >
                    <lay-form-item label="金额" prop="amount">
                    <lay-input v-model="payInfo.amount"></lay-input>
                    </lay-form-item>
                    <lay-form-item label="天数" prop="days" >
                    <lay-input v-model="payInfo.days" ></lay-input>
                    </lay-form-item>   
                    <lay-form-item label="说明" prop="days" >
                    <lay-input v-model="payInfo.caption" ></lay-input>
                    </lay-form-item>  
                </lay-form>
                </div>
            </lay-layer>

        </lay-col>

        <lay-col md="16">
            <lay-panel style="padding: 10px">
                <lay-table 
                        :page="paymentChannelPage" 
                        :height="'100%'"
                        :columns="paymentChannel" 
                        :loading="loading"
                        :default-toolbar="false"
                        :data-source="paymentChannelPdataSource" 
                        v-model:selected-keys="selectedKeys"  
                        @change="paymentChannelChange"
                        @sortChange="sortChange"> 
                        <template v-slot:toolbar> 
                            <div style="  display: flex;      align-items: center;">  
                            <div style="margin-right: auto; "> <lay-button type="primary" @click="addPaymentChannelInfo">添加</lay-button></div>
                            <div style="  text-align: center; ">
                                <h1>支付渠道列表</h1>
                            </div>
                        </div>
                          
                        </template> 
                        <template v-slot:operator="{ row }"> 
                        <lay-button size="xs" @click="paymentChannelRemove(row)">删除</lay-button>
                        </template>
                        <template #status="{ row }">
                            <lay-switch :model-value="row.state == 0 ? true : false"  @change="paymentChannelChangeStatus(row)"></lay-switch>
                            </template>
                            <template #platform="{ row }">
                             <span v-if="row.platform==0">易安支付</span>
                             <span v-else-if="row.platform==1">聚财支付</span>
                            </template>

                            
                    </lay-table> 
            </lay-panel>

            <lay-layer v-model="isPaymentChannelInfo" :shade="false" :area="['400px', '620px']" :btn="paymentChannelBtn" title="添加支付渠道">
                <div style="padding: 20px;">
                <lay-form :model="paymentChannelInfo"   >
                    <lay-form-item label="api地址" prop="apiUrl">
                    <lay-input v-model="paymentChannelInfo.apiUrl"></lay-input>
                    </lay-form-item>
                    <lay-form-item label="平台" prop="platform" >
                 
                    <lay-select v-model="paymentChannelInfo.platform" placeholder="请选择">
                        <lay-select-option :value="0" label="易安支付"></lay-select-option>
                        <lay-select-option :value="1" label="聚财支付"></lay-select-option>
                        
                    </lay-select>
                    </lay-form-item> 

                    
                    
                    <lay-form-item label="商户id" prop="merchantId" >
                    <lay-input v-model="paymentChannelInfo.merchantId" ></lay-input>
                    </lay-form-item> 

                    <lay-form-item label="支付宝编码" prop="payCode" >
                    <lay-input v-model="paymentChannelInfo.payCode" ></lay-input>
                    </lay-form-item> 

                    <lay-form-item label="微信编码" prop="weChatCode" >
                    <lay-input v-model="paymentChannelInfo.weChatCode" ></lay-input>
                    </lay-form-item> 

                    <lay-form-item label="支付回调地址" prop="callbackUrl" >
                    <lay-input v-model="paymentChannelInfo.callbackUrl" ></lay-input>
                    </lay-form-item> 

                    <lay-form-item label="充值成功地址" prop="successPageUrl" >
                    <lay-input v-model="paymentChannelInfo.successPageUrl" ></lay-input>
                    </lay-form-item> 

                    <lay-form-item label="商户key" prop="merchantKey" >
                    <lay-input v-model="paymentChannelInfo.merchantKey" >></lay-input>
                    </lay-form-item> 
                    
                    
                </lay-form>
                </div>
            </lay-layer>
        </lay-col>
    </lay-row>
</template>

<script  lang="ts" setup>
import { ref, reactive } from 'vue';
import { layer } from '@layui/layui-vue';

import { addPayInfo,allPayInfo,payInfoState,delPayInfo,addPaymentChannel,allPaymentChannel,paymentChannelState,delPaymentChannel } from '../../api'


const loading = ref(false); 
    const selectedKeys:any = ref([]);
    const payIndataSource = ref([])

    const payInfoPage = reactive({ current: 1, limit: 10, total: 0 });

    const columns = ref([ 
      { title:"序号",key:"id",align:"center" },
      { title:"金额", key:"amount" ,align:"center" }, 
      { title:"天数", key:"days" ,align:"center" },   
      { title:"说明", key:"caption" ,align:"center" },   
      { title:"状态", width: "80px", key:"state" ,align:"center",customSlot:'status'},
      { title:"时间", width: "120px", key:"addTime" ,align:"center"},
      { title:"操作", width: "60px", customSlot:"operator", key:"operator", fixed: "right", ignoreExport: true }
    ]);

    const  paymentChannel= ref([ 
      { title:"序号", width: "80px", key:"id",align:"center" },
      { title:"api地址", key:"apiUrl" ,align:"center" }, 
      { title:"平台", key:"platform" ,align:"center",customSlot:'platform' }, 
      { title:"商户id", key:"merchantId" ,align:"center" }, 
      { title:"支付宝编码", key:"payCode" ,align:"center" },   
      { title:"微信编码", key:"weChatCode" ,align:"center" },   
      { title:"支付回调地址", key:"callbackUrl" ,align:"center" },   
      { title:"充值成功地址", key:"successPageUrl" ,align:"center" },   
      { title:"商户key", key:"merchantKey" ,align:"center" }, 
      { title:"状态", width: "80px", key:"state" ,align:"center",customSlot:'status'},
      { title:"时间", width: "120px", key:"joinTime" ,align:"center"},
      { title:"操作", width: "60px", customSlot:"operator", key:"operator", fixed: "right", ignoreExport: true }
     
    ]);

   const  getAllPayInfo=()=>{

    var data = { "page": payInfoPage.current, "limit": payInfoPage.limit};
    allPayInfo(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
        payIndataSource.value = []; 
      payIndataSource.value = res.data;
      payInfoPage.total = res.count;
    } else {
        payIndataSource.value = [];
        payInfoPage.total = 0;
    }
  })

   }

   getAllPayInfo();  

    const payInfoChange = (page:any) => {
        getAllPayInfo(); 
    }

    const sortChange = (key:any, sort:any) => {
      layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
    }



    const payInfoChangeStatus = (isChecked:any, row:any) => {
         var data={"id":row.id,"key":row.state};
        payInfoState(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
         getAllPayInfo();  
        }
      })

 
    }

    const payInfoRemove = (row:any) => {
        var data={"id":row.id,"key":row.state};
        delPayInfo(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
            layer.msg("删除成功", { time: 1000, icon: 1 })
             getAllPayInfo();  
        }
      })
    }

  






   const isPayInfo=ref(false) 
//添加支付信息
  
  const  addPayInfos=()=>{ 
    isPayInfo.value = !isPayInfo.value; 
  }


  const payInfo = reactive({
    amount: "",
    days:"",
    caption:""
})


const  payInfoBtn= ref([
    {
        text: "确认",
        callback: () => { 
       
            addPayInfo(JSON.stringify(payInfo)).then((res: any) => {
                      if (res != "" && res.code == 0) {
                        getAllPayInfo(); 
                        layer.msg("操作成功", { time: 1000, icon: 1 })
                        isPayInfo.value = !isPayInfo.value; 
                      }
                    })

        }
    },
    {
        text: "取消",
        callback: () => {
            isPayInfo.value = !isPayInfo.value; 
        }
    }
])


//支付渠道
const  isPaymentChannelInfo=ref(false) ;
const  addPaymentChannelInfo=()=>{ 
    isPaymentChannelInfo.value = !isPaymentChannelInfo.value; 
  }


  const paymentChannelInfo = reactive({
    apiUrl: "",
    platform:"0",
    merchantId:"",
    payCode:"",
    weChatCode:"",
    callbackUrl:"",
    successPageUrl:"",
    merchantKey:"", 
})
  

  const  paymentChannelBtn= ref([
    {
        text: "确认",
        callback: () => { 
       
            addPaymentChannel(JSON.stringify(paymentChannelInfo)).then((res: any) => {
                      if (res != "" && res.code == 0) {
                        getAllPaymentChannel();
                        layer.msg("操作成功", { time: 1000, icon: 1 })
                        isPaymentChannelInfo.value = !isPaymentChannelInfo.value; 
                      }
                    })

            
        }
    },
    {
        text: "取消",
        callback: () => {
            isPaymentChannelInfo.value = !isPaymentChannelInfo.value; 
        }
    }
])

//显示信息


const paymentChannelPage = reactive({ current: 1, limit: 10, total: 0 });

 const paymentChannelPdataSource=ref([])


const  getAllPaymentChannel=()=>{

    var data = { "page": paymentChannelPage.current, "limit": paymentChannelPage.limit};
    allPaymentChannel(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
        paymentChannelPdataSource.value = []; 
        paymentChannelPdataSource.value = res.data;
      payInfoPage.total = res.count;
    } else {
        paymentChannelPdataSource.value = [];
        paymentChannelPage.total = 0;
    }
  })
}

getAllPaymentChannel();
const  paymentChannelChange=()=>{

    getAllPaymentChannel();
}


const paymentChannelChangeStatus = ( row:any) => {
         var data={"id":row.id,"key":row.state};
         paymentChannelState(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
            getAllPaymentChannel();
        }
      })

 
    }

    const paymentChannelRemove = (row:any) => {
        var data={"id":row.id,"key":row.state};
        delPaymentChannel(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
            layer.msg("删除成功", { time: 1000, icon: 1 })
            getAllPaymentChannel();
        }
      })
    }



</script>
