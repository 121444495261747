<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="账号" prop="key" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
          </lay-form-item>
          <lay-form-item label="会员等级" prop="type" mode="inline">
            <lay-select v-model="search.type">
              <lay-select-option value="0" label="全部"></lay-select-option>
              <lay-select-option value="1" label="普通"></lay-select-option>
              <lay-select-option value="2" label="会员"></lay-select-option>

            </lay-select>
          </lay-form-item>

          <lay-form-item label="状态" prop="state" mode="inline">
            <lay-select v-model="search.state">
              <lay-select-option value="0" label="全部"></lay-select-option>
              <lay-select-option value="1" label="正常"></lay-select-option>
              <lay-select-option value="2" label="禁用"></lay-select-option>

            </lay-select>
          </lay-form-item>

          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
          </lay-form-item>

        </lay-form>
        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          <template #delFlag="{ row }">
            <lay-switch :model-value="row.delFlag == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
              unswitch-text="禁用"></lay-switch>
          </template>

          <template #member="{ row }">
            <span v-if="row.member == 0">普通</span>
            <span v-else>会员</span>
          </template>

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
          </template>
        </lay-table>



      </lay-panel>
    </lay-col>

  </lay-row>


  <lay-layer v-model="visible11" :shade="false" :area="['350px', '550px']" :btn="action11" title="修改用户信息">
    <div style="padding: 20px;">
      <lay-form :model="user" ref="layFormRef11" :pane="true">
        <lay-form-item label="账户" prop="userName" required>
          <lay-input v-model="user.userName" :disabled="isDisabled"></lay-input>
        </lay-form-item>
        <lay-form-item label="密码" prop="passWord">
          <lay-input v-model="user.passWord" type="text" placeholder="无需修改请置空"></lay-input>
        </lay-form-item>

        <lay-form-item label="会员等级" prop="member" required>
          <lay-select v-model="user.member">
            <lay-select-option value="0" label="普通"></lay-select-option>
            <lay-select-option value="1" label="会员"></lay-select-option>

          </lay-select>
        </lay-form-item>

        <lay-form-item label="到期时间" prop="expTime" v-if="user.member != '0'" required>
          <lay-date-picker type="datetime" v-model="user.expTime" placeholder="请选择会员到期时间"></lay-date-picker>
        </lay-form-item>

        <lay-form-item label="备注" prop="notes" required>

          <lay-textarea placeholder="请输入备注" v-model="user.notes">
          </lay-textarea>

        </lay-form-item>



      </lay-form>
    </div>
  </lay-layer>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'

import { allUser, disableUsers, modifyUserInfo } from '../../api'




const search = reactive({
  key: "",
  state: "0",
  type: "0",
})


const loading = ref(false);

const selectedKeys = ref([]);

const dataSource = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "用户ID", width: "260px", key: "userId", align: "center" },
  { title: "账号", key: "userName", align: "center" },
  { title: "邮箱", key: "mail", align: "center" },
  { title: "状态", key: "delFlag", align: "center", customSlot: "delFlag" },
  { title: "会员等级", key: "member", align: "center", customSlot: "member" },
  { title: "到期时间", key: "expTime", align: "center" },
  { title: "备注", key: "notes", align: "center" },
  { title: "注册时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
]);


const getAllUser = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key, "state": search.state, "type": search.type };
  allUser(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}

getAllUser();

const change = (page: any) => {
  getAllUser();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}


//禁用或者开启
const changedelFlag = (row: any) => {
  console.log(row.userId)
  var data = { "key": row.userId, "id": row.delFlag };
  disableUsers(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllUser();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}


//搜索
const searchUser=()=>{
  page.current=1;

  getAllUser();
}




const user = reactive({
  userId: "",
  userName: "",
  passWord: "",
  member: "0",
  expTime: "",
  notes: ""
})


const updateUser = (row: any) => {
  user.userId = row.userId;
  user.userName = row.userName;
  user.member = row.member + "";
  user.expTime = row.expTime;
  user.notes = row.notes==null?"":row.notes;
  user.passWord = "";
  isDisabled.value = true;
  visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const isDisabled = ref(false);

const action11 = ref([
  {
    text: "确认",
    callback: () => {

      if (user.member != "0") {
        if (user.expTime == null) {
          layer.msg("请输入会员到期时间", { icon: 7, time: 1000 })
          return false;
        }

      }

      if (user.notes.length==0) {
        
        layer.msg("请输入备注", { icon: 7, time: 1000 })
        return false;
      }



      modifyUserInfo(JSON.stringify(user)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllUser();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false;
    }
  }
])
</script>

<style scoped></style>