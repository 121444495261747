<template>
    <lay-row space="10">
        <lay-col md="4">
            <lay-panel style="padding: 10px">
                <div>
                    <div>新用户</div>
                    <div class="numericalValue">{{ statistics.newUser }}</div>
                </div>
                <div class="totalVal">
                    <div>总用户：</div>
                    <div>{{ statistics.totalUsers }}</div>
                </div>

            </lay-panel>
        </lay-col>
        <lay-col md="4">
         
                <lay-panel style="padding: 10px">
                    <div>
                        <div>会员</div>
                        <div class="numericalValue">{{ statistics.newMember }}</div>
                    </div>
                    <div class="totalVal">
                        <div>总会员：</div>
                        <div>{{ statistics.totalMembers }}</div>
                    </div>

                </lay-panel>
          
        </lay-col>
        <lay-col md="4"> 
                <lay-panel style="padding: 10px">
                    <div>
                        <div>今日收益</div>
                        <div class="numericalValue">{{ statistics.todayEarnings }}</div>
                    </div>
                    <div class="totalVal">
                        <div>总收益：</div>
                        <div>{{ statistics.totalRevenue }}</div>
                    </div>  
            </lay-panel>
        </lay-col>

        <lay-col md="4"> 
                <lay-panel style="padding: 10px">
                    <div>
                        <div>充值成功</div>
                        <div class="numericalValue">{{ statistics.rechargeSuccessful }}</div>
                    </div>
                    <div class="totalVal">
                        <div>充值失败:</div>
                        <div>{{ statistics.rechargeFailed }}</div>
                    </div> 
            </lay-panel>
        </lay-col>

        <lay-col md="4"> 
                <lay-panel style="padding: 10px">
                    <div>
                        <div>待审核</div>
                        <div class="numericalValue">{{ statistics.pendingReview }}</div>
                    </div>
                    <div class="totalVal">
                        <div>审核失败：</div>
                        <div>{{ statistics.auditFailure }}</div>
                    </div> 
            </lay-panel>
        </lay-col>
        <lay-col md="4"> 
                <lay-panel style="padding: 10px">
                    <div>
                        <div>今日楼凤</div>
                        <div class="numericalValue">{{ statistics.todayLoufeng }}</div>
                    </div>
                    <div class="totalVal">
                        <div>总楼凤：</div>
                        <div>{{ statistics.generalLoufeng }}</div>
                    </div> 
            </lay-panel>
        </lay-col>

       

    </lay-row>


    <lay-row space="10">
        <lay-col md="12">
            <lay-panel style="padding: 10px">
                <lay-table 
                        :page="page" 
                        :height="'100%'"
                        :columns="columns" 
                        :loading="loading"
                        :default-toolbar="false"
                        :data-source="dataSource" 
                        v-model:selected-keys="selectedKeys"  
                        @change="change"
                        @sortChange="sortChange"> 
                        <template v-slot:toolbar>
                            <h1>充值明显</h1>
                        </template>
                       
                      
                    </lay-table>
            </lay-panel>
        </lay-col>
        <lay-col md="12">
            <lay-panel style="padding: 10px">
                <lay-table 
                        :page="page"  
                        :height="'100%'"
                        :columns="columns" 
                        :loading="loading"
                        :default-toolbar="false"
                        :data-source="dataSource" 
                        v-model:selected-keys="selectedKeys"  
                        @change="change"
                        @sortChange="sortChange"> 
                        <template v-slot:toolbar>
                            <h1>登录信息</h1>
                        </template> 
                    </lay-table>
            </lay-panel>
        </lay-col> 
    </lay-row> 
</template>
<script  lang="ts" setup>
import { ref, reactive } from 'vue';
import { layer } from '@layui/layui-vue';
import { allStatistics } from '../../api' 




const statistics = reactive({
    totalUsers: "0",
    newUser:"0",
    newMember:"0",
    totalMembers:"0",
    todayEarnings:'0',
    totalRevenue:'0',
    rechargeSuccessful:'0',
    rechargeFailed:'0',
    pendingReview:'0',
    auditFailure:'0',
    todayLoufeng:'0',
    generalLoufeng:'0',
})

 


const getAllStatistics=()=>{

    allStatistics().then((res: any) => {
    if (res != "" && res.code == 0) {  
        statistics.newUser=res.data.newUser;
        statistics.totalUsers=res.data.totalUsers;
        statistics.newMember=res.data.newMember;
        statistics.totalMembers=res.data.totalMembers;
        statistics.todayEarnings=res.data.todayEarnings; 
       statistics.totalRevenue=res.data.totalRevenue; 
       statistics.rechargeSuccessful=res.data.rechargeSuccessful; 
       statistics.rechargeFailed=res.data.rechargeFailed; 
       statistics.pendingReview=res.data.pendingReview; 
       statistics.auditFailure=res.data.auditFailure; 
       statistics.todayLoufeng=res.data.todayLoufeng; 
       statistics.generalLoufeng=res.data.generalLoufeng;  
        
    }
  })

}

getAllStatistics();

const loading = ref(false); 
    const selectedKeys = ref([]);

    const page = reactive({ current: 1, limit: 10, total: 100 });

    const columns = ref([ 
      { title:"序号", width: "80px", key:"id",align:"center" },
      { title:"账号", key:"name" ,align:"center" }, 
      { title:"订单编号", key:"name" ,align:"center" }, 
      { title:"金额", width: "120px", key:"email", sort: "desc" ,align:"center" }, 
      { title:"状态", width: "80px", key:"sex" ,align:"center"},
      { title:"时间", width: "120px", key:"joinTime" ,align:"center"},
     
    ]);

    const change = (page:any) => {
      loading.value = true;
      setTimeout(() => {
     //   dataSource.value = loadDataSource(page.current, page.limit);
        loading.value = false;
      }, 1000);
    }

    const sortChange = (key:any, sort:any) => {
      layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
    }

    const dataSource = ref( )

    const changeStatus = (isChecked:any, row:any) => {
      dataSource.value.forEach((item:any) => {
        if(item.id === row.id) {
          layer.msg("Success", { icon: 1 }, () => {
            item.status = isChecked;
          })
        }
      })
    }

 

 
</script>

<style scoped>
.numericalValue {
    margin-top: 10px;
    font-size: 20px;
}

.totalVal {
    display: flex;
    font-size: 14px;
    margin-top: 5px;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: center;
    align-items: center;
}
</style>

 