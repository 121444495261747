const secondaryCities = [
    {
      "value": "110000",
      "label": "北京",
      "children": [
        {
          "value": "110100",
          "fathervalue": "110000",
          "fatherLabel": "北京",
          "label": "北京市"
        },
        {
          "value": "110101",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "东城区"
        },
        {
          "value": "110102",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "西城区"
        },
        {
          "value": "110105",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "朝阳区"
        },
        {
          "value": "110106",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "丰台区"
        },
        {
          "value": "110107",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "石景山区"
        },
        {
          "value": "110108",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "海淀区"
        },
        {
          "value": "110109",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "门头沟区"
        },
        {
          "value": "110111",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "房山区"
        },
        {
          "value": "110112",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "通州区"
        },
        {
          "value": "110113",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "顺义区"
        },
        {
          "value": "110114",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "昌平区"
        },
        {
          "value": "110115",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "大兴区"
        },
        {
          "value": "110116",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "怀柔区"
        },
        {
          "value": "110117",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "平谷区"
        },
        {
          "value": "110118",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "密云区"
        },
        {
          "value": "110119",
          "fathervalue": "110000",
          "fatherLabel": "北京市",
          "label": "延庆区"
        },
      ]
    },
    {
      "value": "120000",
      "label": "天津市",
      "children": [
        {
          "value": "120101",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "和平区"
        },
        {
          "value": "120102",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "河东区"
        },
        {
          "value": "120103",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "河西区"
        },
        {
          "value": "120104",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "南开区"
        },
        {
          "value": "120105",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "河北区"
        },
        {
          "value": "120106",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "红桥区"
        },
        {
          "value": "120110",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "东丽区"
        },
        {
          "value": "120111",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "西青区"
        },
        {
          "value": "120112",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "津南区"
        },
        {
          "value": "120113",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "北辰区"
        },
        {
          "value": "120114",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "武清区"
        },
        {
          "value": "120115",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "宝坻区"
        },
        {
          "value": "120116",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "滨海新区"
        },
        {
          "value": "120117",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "宁河区"
        },
        {
          "value": "120118",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "静海区"
        },
        {
          "value": "120119",
          "fathervalue": "120000",
          "fatherLabel": "天津市",
          "label": "蓟州区"
        },
      ]
    },
    {
      "value": "130000",
      "label": "河北省",
      "children": [
        {
          "value": "130100",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "石家庄市"
        },
        {
          "value": "130200",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "唐山市"
        },
        {
          "value": "130300",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "秦皇岛市"
        },
        {
          "value": "130400",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "邯郸市"
        },
        {
          "value": "130500",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "邢台市"
        },
        {
          "value": "130600",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "保定市"
        },
        {
          "value": "130700",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "张家口市"
        },
        {
          "value": "130800",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "承德市"
        },
        {
          "value": "130900",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "沧州市"
        },
        {
          "value": "131000",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "廊坊市"
        },
        {
          "value": "131100",
          "fathervalue": "130000",
          "fatherLabel": "河北省",
          "label": "衡水市"
        },
      ]
    },
    {
      "value": "140000",
      "label": "山西省",
      "children": [
        {
          "value": "140100",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "太原市"
        },
        {
          "value": "140200",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "大同市"
        },
        {
          "value": "140300",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "阳泉市"
        },
        {
          "value": "140400",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "长治市"
        },
        {
          "value": "140500",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "晋城市"
        },
        {
          "value": "140600",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "朔州市"
        },
        {
          "value": "140700",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "晋中市"
        },
        {
          "value": "140800",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "运城市"
        },
        {
          "value": "140900",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "忻州市"
        },
        {
          "value": "141000",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "临汾市"
        },
        {
          "value": "141100",
          "fathervalue": "140000",
          "fatherLabel": "山西省",
          "label": "吕梁市"
        },
      ]
    },
    {
      "value": "150000",
      "label": "内蒙古自治区",
      "children": [
        {
          "value": "150100",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "呼和浩特市"
        },
        {
          "value": "150200",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "包头市"
        },
        {
          "value": "150300",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "乌海市"
        },
        {
          "value": "150400",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "赤峰市"
        },
        {
          "value": "150500",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "通辽市"
        },
        {
          "value": "150600",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "鄂尔多斯市"
        },
        {
          "value": "150700",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "呼伦贝尔市"
        },
        {
          "value": "150800",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "巴彦淖尔市"
        },
        {
          "value": "150900",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "乌兰察布市"
        },
        {
          "value": "152200",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "兴安盟"
        },
        {
          "value": "152500",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "锡林郭勒盟"
        },
        {
          "value": "152900",
          "fathervalue": "150000",
          "fatherLabel": "内蒙古自治区",
          "label": "阿拉善盟"
        },
      ]
    },
    {
      "value": "210000",
      "label": "辽宁省",
      "children": [
        {
          "value": "210100",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "沈阳市"
        },
        {
          "value": "210200",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "大连市"
        },
        {
          "value": "210300",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "鞍山市"
        },
        {
          "value": "210400",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "抚顺市"
        },
        {
          "value": "210500",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "本溪市"
        },
        {
          "value": "210600",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "丹东市"
        },
        {
          "value": "210700",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "锦州市"
        },
        {
          "value": "210800",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "营口市"
        },
        {
          "value": "210900",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "阜新市"
        },
        {
          "value": "211000",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "辽阳市"
        },
        {
          "value": "211100",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "盘锦市"
        },
        {
          "value": "211300",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "朝阳市"
        },
        {
          "value": "211400",
          "fathervalue": "210000",
          "fatherLabel": "辽宁省",
          "label": "葫芦岛市"
        },
      ]
    },
    {
      "value": "220000",
      "label": "吉林省",
      "children": [
        {
          "value": "220100",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "长春市"
        },
        {
          "value": "220200",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "吉林市"
        },
        {
          "value": "220300",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "四平市"
        },
        {
          "value": "220400",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "辽源市"
        },
        {
          "value": "220500",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "通化市"
        },
        {
          "value": "220600",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "白山市"
        },
        {
          "value": "220700",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "松原市"
        },
        {
          "value": "220800",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "白城市"
        },
        {
          "value": "222400",
          "fathervalue": "220000",
          "fatherLabel": "吉林省",
          "label": "延边朝鲜族自治州"
        },
      ]
    },
    {
      "value": "230000",
      "label": "黑龙江省",
      "children": [
        {
          "value": "230100",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "哈尔滨市"
        },
        {
          "value": "230200",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "齐齐哈尔市"
        },
        {
          "value": "230300",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "鸡西市"
        },
        {
          "value": "230400",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "鹤岗市"
        },
        {
          "value": "230500",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "双鸭山市"
        },
        {
          "value": "230600",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "大庆市"
        },
        {
          "value": "230700",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "伊春市"
        },
        {
          "value": "230800",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "佳木斯市"
        },
        {
          "value": "230900",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "七台河市"
        },
        {
          "value": "231000",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "牡丹江市"
        },
        {
          "value": "231100",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "黑河市"
        },
        {
          "value": "231200",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "绥化市"
        },
        {
          "value": "232700",
          "fathervalue": "230000",
          "fatherLabel": "黑龙江省",
          "label": "大兴安岭地区"
        },
      ]
    },
    {
      "value": "310000",
      "label": "上海市",
      "children": [
        {
          "value": "310101",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "黄浦区"
        },
        {
          "value": "310104",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "徐汇区"
        },
        {
          "value": "310105",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "长宁区"
        },
        {
          "value": "310106",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "静安区"
        },
        {
          "value": "310107",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "普陀区"
        },
        {
          "value": "310109",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "虹口区"
        },
        {
          "value": "310110",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "杨浦区"
        },
        {
          "value": "310112",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "闵行区"
        },
        {
          "value": "310113",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "宝山区"
        },
        {
          "value": "310114",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "嘉定区"
        },
        {
          "value": "310115",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "浦东新区"
        },
        {
          "value": "310116",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "金山区"
        },
        {
          "value": "310117",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "松江区"
        },
        {
          "value": "310118",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "青浦区"
        },
        {
          "value": "310120",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "奉贤区"
        },
        {
          "value": "310151",
          "fathervalue": "310000",
          "fatherLabel": "上海市",
          "label": "崇明区"
        },
      ]
    },
    {
      "value": "320000",
      "label": "江苏省",
      "children": [
        {
          "value": "320100",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "南京市"
        },
        {
          "value": "320200",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "无锡市"
        },
        {
          "value": "320300",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "徐州市"
        },
        {
          "value": "320400",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "常州市"
        },
        {
          "value": "320500",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "苏州市"
        },
        {
          "value": "320600",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "南通市"
        },
        {
          "value": "320700",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "连云港市"
        },
        {
          "value": "320800",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "淮安市"
        },
        {
          "value": "320900",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "盐城市"
        },
        {
          "value": "321000",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "扬州市"
        },
        {
          "value": "321100",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "镇江市"
        },
        {
          "value": "321200",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "泰州市"
        },
        {
          "value": "321300",
          "fathervalue": "320000",
          "fatherLabel": "江苏省",
          "label": "宿迁市"
        },
      ]
    },
    {
      "value": "330000",
      "label": "浙江省",
      "children": [
        {
          "value": "330100",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "杭州市"
        },
        {
          "value": "330200",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "宁波市"
        },
        {
          "value": "330300",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "温州市"
        },
        {
          "value": "330400",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "嘉兴市"
        },
        {
          "value": "330500",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "湖州市"
        },
        {
          "value": "330600",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "绍兴市"
        },
        {
          "value": "330700",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "金华市"
        },
        {
          "value": "330800",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "衢州市"
        },
        {
          "value": "330900",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "舟山市"
        },
        {
          "value": "331000",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "台州市"
        },
        {
          "value": "331100",
          "fathervalue": "330000",
          "fatherLabel": "浙江省",
          "label": "丽水市"
        },
      ]
    },
    {
      "value": "340000",
      "label": "安徽省",
      "children": [
        {
          "value": "340100",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "合肥市"
        },
        {
          "value": "340200",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "芜湖市"
        },
        {
          "value": "340300",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "蚌埠市"
        },
        {
          "value": "340400",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "淮南市"
        },
        {
          "value": "340500",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "马鞍山市"
        },
        {
          "value": "340600",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "淮北市"
        },
        {
          "value": "340700",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "铜陵市"
        },
        {
          "value": "340800",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "安庆市"
        },
        {
          "value": "341000",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "黄山市"
        },
        {
          "value": "341100",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "滁州市"
        },
        {
          "value": "341200",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "阜阳市"
        },
        {
          "value": "341300",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "宿州市"
        },
        {
          "value": "341500",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "六安市"
        },
        {
          "value": "341600",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "亳州市"
        },
        {
          "value": "341700",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "池州市"
        },
        {
          "value": "341800",
          "fathervalue": "340000",
          "fatherLabel": "安徽省",
          "label": "宣城市"
        },
      ]
    },
    {
      "value": "350000",
      "label": "福建省",
      "children": [
        {
          "value": "350100",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "福州市"
        },
        {
          "value": "350200",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "厦门市"
        },
        {
          "value": "350300",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "莆田市"
        },
        {
          "value": "350400",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "三明市"
        },
        {
          "value": "350500",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "泉州市"
        },
        {
          "value": "350600",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "漳州市"
        },
        {
          "value": "350700",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "南平市"
        },
        {
          "value": "350800",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "龙岩市"
        },
        {
          "value": "350900",
          "fathervalue": "350000",
          "fatherLabel": "福建省",
          "label": "宁德市"
        },
      ]
    },
    {
      "value": "360000",
      "label": "江西省",
      "children": [
        {
          "value": "360100",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "南昌市"
        },
        {
          "value": "360200",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "景德镇市"
        },
        {
          "value": "360300",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "萍乡市"
        },
        {
          "value": "360400",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "九江市"
        },
        {
          "value": "360500",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "新余市"
        },
        {
          "value": "360600",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "鹰潭市"
        },
        {
          "value": "360700",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "赣州市"
        },
        {
          "value": "360800",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "吉安市"
        },
        {
          "value": "360900",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "宜春市"
        },
        {
          "value": "361000",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "抚州市"
        },
        {
          "value": "361100",
          "fathervalue": "360000",
          "fatherLabel": "江西省",
          "label": "上饶市"
        },
      ]
    },
    {
      "value": "370000",
      "label": "山东省",
      "children": [
        {
          "value": "370100",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "济南市"
        },
        {
          "value": "370200",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "青岛市"
        },
        {
          "value": "370300",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "淄博市"
        },
        {
          "value": "370400",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "枣庄市"
        },
        {
          "value": "370500",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "东营市"
        },
        {
          "value": "370600",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "烟台市"
        },
        {
          "value": "370700",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "潍坊市"
        },
        {
          "value": "370800",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "济宁市"
        },
        {
          "value": "370900",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "泰安市"
        },
        {
          "value": "371000",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "威海市"
        },
        {
          "value": "371100",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "日照市"
        },
        {
          "value": "371200",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "莱芜市"
        },
        {
          "value": "371300",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "临沂市"
        },
        {
          "value": "371400",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "德州市"
        },
        {
          "value": "371500",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "聊城市"
        },
        {
          "value": "371600",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "滨州市"
        },
        {
          "value": "371700",
          "fathervalue": "370000",
          "fatherLabel": "山东省",
          "label": "菏泽市"
        },
      ]
    },
    {
      "value": "410000",
      "label": "河南省",
      "children": [
        {
          "value": "410100",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "郑州市"
        },
        {
          "value": "410200",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "开封市"
        },
        {
          "value": "410300",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "洛阳市"
        },
        {
          "value": "410400",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "平顶山市"
        },
        {
          "value": "410500",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "安阳市"
        },
        {
          "value": "410600",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "鹤壁市"
        },
        {
          "value": "410700",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "新乡市"
        },
        {
          "value": "410800",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "焦作市"
        },
        {
          "value": "410900",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "濮阳市"
        },
        {
          "value": "411000",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "许昌市"
        },
        {
          "value": "411100",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "漯河市"
        },
        {
          "value": "411200",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "三门峡市"
        },
        {
          "value": "411300",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "南阳市"
        },
        {
          "value": "411400",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "商丘市"
        },
        {
          "value": "411500",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "信阳市"
        },
        {
          "value": "411600",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "周口市"
        },
        {
          "value": "411700",
          "fathervalue": "410000",
          "fatherLabel": "河南省",
          "label": "驻马店市"
        },
      ]
    },
    {
      "value": "420000",
      "label": "湖北省",
      "children": [
        {
          "value": "420100",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "武汉市"
        },
        {
          "value": "420200",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "黄石市"
        },
        {
          "value": "420300",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "十堰市"
        },
        {
          "value": "420500",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "宜昌市"
        },
        {
          "value": "420600",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "襄阳市"
        },
        {
          "value": "420700",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "鄂州市"
        },
        {
          "value": "420800",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "荆门市"
        },
        {
          "value": "420900",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "孝感市"
        },
        {
          "value": "421000",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "荆州市"
        },
        {
          "value": "421100",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "黄冈市"
        },
        {
          "value": "421200",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "咸宁市"
        },
        {
          "value": "421300",
          "fathervalue": "420000",
          "fatherLabel": "湖北省",
          "label": "随州市"
        },
      ]
    },
    {
      "value": "430000",
      "label": "湖南省",
      "children": [
        {
          "value": "430100",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "长沙市"
        },
        {
          "value": "430200",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "株洲市"
        },
        {
          "value": "430300",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "湘潭市"
        },
        {
          "value": "430400",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "衡阳市"
        },
        {
          "value": "430500",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "邵阳市"
        },
        {
          "value": "430600",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "岳阳市"
        },
        {
          "value": "430700",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "常德市"
        },
        {
          "value": "430800",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "张家界市"
        },
        {
          "value": "430900",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "益阳市"
        },
        {
          "value": "431000",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "郴州市"
        },
        {
          "value": "431100",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "永州市"
        },
        {
          "value": "431200",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "怀化市"
        },
        {
          "value": "431300",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "娄底市"
        },
        {
          "value": "433100",
          "fathervalue": "430000",
          "fatherLabel": "湖南省",
          "label": "湘西土家族苗族自治州"
        },
      ]
    },
    {
      "value": "440000",
      "label": "广东省",
      "children": [
        {
          "value": "440100",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "广州市"
        },
        {
          "value": "440200",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "韶关市"
        },
        {
          "value": "440300",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "深圳市"
        },
        {
          "value": "440400",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "珠海市"
        },
        {
          "value": "440500",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "汕头市"
        },
        {
          "value": "440600",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "佛山市"
        },
        {
          "value": "440700",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "江门市"
        },
        {
          "value": "440800",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "湛江市"
        },
        {
          "value": "440900",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "茂名市"
        },
        {
          "value": "441200",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "肇庆市"
        },
        {
          "value": "441300",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "惠州市"
        },
        {
          "value": "441400",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "梅州市"
        },
        {
          "value": "441500",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "汕尾市"
        },
        {
          "value": "441600",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "河源市"
        },
        {
          "value": "441700",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "阳江市"
        },
        {
          "value": "441800",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "清远市"
        },
        {
          "value": "441900",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "东莞市"
        },
        {
          "value": "442000",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "中山市"
        },
        {
          "value": "445100",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "潮州市"
        },
        {
          "value": "445200",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "揭阳市"
        },
        {
          "value": "445300",
          "fathervalue": "440000",
          "fatherLabel": "广东省",
          "label": "云浮市"
        },
      ]
    },
    {
      "value": "450000",
      "label": "广西壮族自治区",
      "children": [
        {
          "value": "450100",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "南宁市"
        },
        {
          "value": "450200",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "柳州市"
        },
        {
          "value": "450300",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "桂林市"
        },
        {
          "value": "450400",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "梧州市"
        },
        {
          "value": "450500",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "北海市"
        },
        {
          "value": "450600",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "防城港市"
        },
        {
          "value": "450700",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "钦州市"
        },
        {
          "value": "450800",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "贵港市"
        },
        {
          "value": "450900",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "玉林市"
        },
        {
          "value": "451000",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "百色市"
        },
        {
          "value": "451100",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "贺州市"
        },
        {
          "value": "451200",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "河池市"
        },
        {
          "value": "451300",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "来宾市"
        },
        {
          "value": "451400",
          "fathervalue": "450000",
          "fatherLabel": "广西壮族自治区",
          "label": "崇左市"
        },
      ]
    },
    {
      "value": "460000",
      "label": "海南省",
      "children": [
        {
          "value": "460100",
          "fathervalue": "460000",
          "fatherLabel": "海南省",
          "label": "海口市"
        },
        {
          "value": "460200",
          "fathervalue": "460000",
          "fatherLabel": "海南省",
          "label": "三亚市"
        },
        {
          "value": "460300",
          "fathervalue": "460000",
          "fatherLabel": "海南省",
          "label": "三沙市"
        },
        {
          "value": "460400",
          "fathervalue": "460000",
          "fatherLabel": "海南省",
          "label": "儋州市"
        },
      ]
    },
    {
      "value": "500000",
      "label": "重庆市",
      "children": [
        {
          "value": "500101",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "万州区"
        },
        {
          "value": "500102",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "涪陵区"
        },
        {
          "value": "500103",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "渝中区"
        },
        {
          "value": "500104",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "大渡口区"
        },
        {
          "value": "500105",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "江北区"
        },
        {
          "value": "500106",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "沙坪坝区"
        },
        {
          "value": "500107",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "九龙坡区"
        },
        {
          "value": "500108",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "南岸区"
        },
        {
          "value": "500109",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "北碚区"
        },
        {
          "value": "500110",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "綦江区"
        },
        {
          "value": "500111",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "大足区"
        },
        {
          "value": "500112",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "渝北区"
        },
        {
          "value": "500113",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "巴南区"
        },
        {
          "value": "500114",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "黔江区"
        },
        {
          "value": "500115",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "长寿区"
        },
        {
          "value": "500116",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "江津区"
        },
        {
          "value": "500117",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "合川区"
        },
        {
          "value": "500118",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "永川区"
        },
        {
          "value": "500119",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "南川区"
        },
        {
          "value": "500120",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "璧山区"
        },
        {
          "value": "500151",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "铜梁区"
        },
        {
          "value": "500152",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "潼南区"
        },
        {
          "value": "500153",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "荣昌区"
        },
        {
          "value": "500154",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "开州区"
        },
        {
          "value": "500155",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "梁平区"
        },
        {
          "value": "500156",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "武隆区"
        },
        {
          "value": "500229",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "城口县"
        },
        {
          "value": "500230",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "丰都县"
        },
        {
          "value": "500231",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "垫江县"
        },
        {
          "value": "500233",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "忠县"
        },
        {
          "value": "500235",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "云阳县"
        },
        {
          "value": "500236",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "奉节县"
        },
        {
          "value": "500237",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "巫山县"
        },
        {
          "value": "500238",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "巫溪县"
        },
        {
          "value": "500240",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "石柱土家族自治县"
        },
        {
          "value": "500241",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "秀山土家族苗族自治县"
        },
        {
          "value": "500242",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "酉阳土家族苗族自治县"
        },
        {
          "value": "500243",
          "fathervalue": "500000",
          "fatherLabel": "重庆市",
          "label": "彭水苗族土家族自治县"
        },
      ]
    },
    {
      "value": "510000",
      "label": "四川省",
      "children": [
        {
          "value": "510100",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "成都市"
        },
        {
          "value": "510300",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "自贡市"
        },
        {
          "value": "510400",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "攀枝花市"
        },
        {
          "value": "510500",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "泸州市"
        },
        {
          "value": "510600",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "德阳市"
        },
        {
          "value": "510700",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "绵阳市"
        },
        {
          "value": "510800",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "广元市"
        },
        {
          "value": "510900",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "遂宁市"
        },
        {
          "value": "510900",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "遂宁市"
        },
        {
          "value": "511000",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "内江市"
        },
        {
          "value": "511100",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "乐山市"
        },
        {
          "value": "511300",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "南充市"
        },
        {
          "value": "511400",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "眉山市"
        },
        {
          "value": "511500",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "宜宾市"
        },
        {
          "value": "511600",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "广安市"
        },
        {
          "value": "511700",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "达州市"
        },
        {
          "value": "511800",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "雅安市"
        },
        {
          "value": "511900",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "巴中市"
        },
        {
          "value": "512000",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "资阳市"
        },
        {
          "value": "513200",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "阿坝藏族羌族自治州"
        },
        {
          "value": "513300",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "甘孜藏族自治州"
        },
        {
          "value": "513400",
          "fathervalue": "510000",
          "fatherLabel": "四川省",
          "label": "凉山彝族自治州"
        },
      ]
    },
    {
      "value": "520000",
      "label": "贵州省",
      "children": [
        {
          "value": "520100",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "贵阳市"
        },
        {
          "value": "520200",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "六盘水市"
        },
        {
          "value": "520300",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "遵义市"
        },
        {
          "value": "520400",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "安顺市"
        },
        {
          "value": "520500",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "毕节市"
        },
        {
          "value": "520600",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "铜仁市"
        },
        {
          "value": "522300",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "黔西南布依族苗族自治州"
        },
        {
          "value": "522600",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "黔东南苗族侗族自治州"
        },
        {
          "value": "522700",
          "fathervalue": "520000",
          "fatherLabel": "贵州省",
          "label": "黔南布依族苗族自治州"
        },
      ]
    },
    {
      "value": "530000",
      "label": "云南省",
      "children": [
        {
          "value": "530100",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "昆明市"
        },
        {
          "value": "530300",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "曲靖市"
        },
        {
          "value": "530400",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "玉溪市"
        },
        {
          "value": "530500",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "保山市"
        },
        {
          "value": "530600",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "昭通市"
        },
        {
          "value": "530700",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "丽江市"
        },
        {
          "value": "530800",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "普洱市"
        },
        {
          "value": "530900",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "临沧市"
        },
        {
          "value": "532300",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "楚雄彝族自治州"
        },
        {
          "value": "532500",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "红河哈尼族彝族自治州"
        },
        {
          "value": "532600",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "文山壮族苗族自治州"
        },
        {
          "value": "532800",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "西双版纳傣族自治州"
        },
        {
          "value": "532900",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "大理白族自治州"
        },
        {
          "value": "533100",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "德宏傣族景颇族自治州"
        },
        {
          "value": "533300",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "怒江傈僳族自治州"
        },
        {
          "value": "533400",
          "fathervalue": "530000",
          "fatherLabel": "云南省",
          "label": "迪庆藏族自治州"
        },
      ]
    },
    {
      "value": "540000",
      "label": "西藏自治区",
      "children": [
        {
          "value": "540100",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "拉萨市"
        },
        {
          "value": "540200",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "日喀则市"
        },
        {
          "value": "540300",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "昌都市"
        },
        {
          "value": "540400",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "林芝市"
        },
        {
          "value": "540500",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "山南市"
        },
        {
          "value": "540600",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "那曲市"
        },
        {
          "value": "542500",
          "fathervalue": "540000",
          "fatherLabel": "西藏自治区",
          "label": "阿里地区"
        },
      ]
    },
    {
      "value": "610000",
      "label": "陕西省",
      "children": [
        {
          "value": "610100",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "西安市"
        },
        {
          "value": "610200",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "铜川市"
        },
        {
          "value": "610300",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "宝鸡市"
        },
        {
          "value": "610400",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "咸阳市"
        },
        {
          "value": "610500",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "渭南市"
        },
        {
          "value": "610600",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "延安市"
        },
        {
          "value": "610700",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "汉中市"
        },
        {
          "value": "610800",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "榆林市"
        },
        {
          "value": "610900",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "安康市"
        },
        {
          "value": "611000",
          "fathervalue": "610000",
          "fatherLabel": "陕西省",
          "label": "商洛市"
        },
      ]
    },
    {
      "value": "620000",
      "label": "甘肃省",
      "children": [
        {
          "value": "620100",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "兰州市"
        },
        {
          "value": "620200",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "嘉峪关市"
        },
        {
          "value": "620300",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "金昌市"
        },
        {
          "value": "620400",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "白银市"
        },
        {
          "value": "620500",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "天水市"
        },
        {
          "value": "620600",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "武威市"
        },
        {
          "value": "620700",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "张掖市"
        },
        {
          "value": "620800",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "平凉市"
        },
        {
          "value": "620900",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "酒泉市"
        },
        {
          "value": "621000",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "庆阳市"
        },
        {
          "value": "621100",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "定西市"
        },
        {
          "value": "621200",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "陇南市"
        },
        {
          "value": "622900",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "临夏回族自治州"
        },
        {
          "value": "623000",
          "fathervalue": "620000",
          "fatherLabel": "甘肃省",
          "label": "甘南藏族自治州"
        },
      ]
    },
    {
      "value": "630000",
      "label": "青海省",
      "children": [
        {
          "value": "630100",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "西宁市"
        },
        {
          "value": "630200",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "海东市"
        },
        {
          "value": "632200",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "海北藏族自治州"
        },
        {
          "value": "632300",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "黄南藏族自治州"
        },
        {
          "value": "632500",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "海南藏族自治州"
        },
        {
          "value": "632600",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "果洛藏族自治州"
        },
        {
          "value": "632700",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "玉树藏族自治州"
        },
        {
          "value": "632800",
          "fathervalue": "630000",
          "fatherLabel": "青海省",
          "label": "海西蒙古族藏族自治州"
        },
      ]
    },
    {
      "value": "640000",
      "label": "宁夏回族自治区",
      "children": [
        {
          "value": "640100",
          "fathervalue": "640000",
          "fatherLabel": "宁夏回族自治区",
          "label": "银川市"
        },
        {
          "value": "640200",
          "fathervalue": "640000",
          "fatherLabel": "宁夏回族自治区",
          "label": "石嘴山市"
        },
        {
          "value": "640300",
          "fathervalue": "640000",
          "fatherLabel": "宁夏回族自治区",
          "label": "吴忠市"
        },
        {
          "value": "640400",
          "fathervalue": "640000",
          "fatherLabel": "宁夏回族自治区",
          "label": "固原市"
        },
        {
          "value": "640500",
          "fathervalue": "640000",
          "fatherLabel": "宁夏回族自治区",
          "label": "中卫市"
        },
      ]
    },
    {
      "value": "650000",
      "label": "新疆维吾尔自治区",
      "children": [
        {
          "value": "650100",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "乌鲁木齐市"
        },
        {
          "value": "650200",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "克拉玛依市"
        },
        {
          "value": "650400",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "吐鲁番市"
        },
        {
          "value": "650500",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "哈密市"
        },
        {
          "value": "652300",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "昌吉回族自治州"
        },
        {
          "value": "652700",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "博尔塔拉蒙古自治州"
        },
        {
          "value": "652800",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "巴音郭楞蒙古自治州"
        },
        {
          "value": "652900",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "阿克苏地区"
        },
        {
          "value": "653000",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "克孜勒苏柯尔克孜自治州"
        },
        {
          "value": "653100",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "喀什地区"
        },
        {
          "value": "653200",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "和田地区"
        },
        {
          "value": "654000",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "伊犁哈萨克自治州"
        },
        {
          "value": "654200",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "塔城地区"
        },
        {
          "value": "654300",
          "fathervalue": "650000",
          "fatherLabel": "新疆维吾尔自治区",
          "label": "阿勒泰地区"
        },
      ]
    },
    {
      "value": "810000",
      "label": "香港特别行政区",
      "children": [
        {
          "value": "810101",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "中西区"
        },
        {
          "value": "810102",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "湾仔区"
        },
        {
          "value": "810103",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "东区"
        },
        {
          "value": "810104",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "南区"
        },
        {
          "value": "810105",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "油尖旺区"
        },
        {
          "value": "810106",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "深水埗区"
        },
        {
          "value": "810107",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "九龙城区"
        },
        {
          "value": "810108",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "黄大仙区"
        },
        {
          "value": "810109",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "观塘区"
        },
        {
          "value": "810110",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "北区"
        },
        {
          "value": "810111",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "大埔区"
        },
        {
          "value": "810112",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "沙田区"
        },
        {
          "value": "810113",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "西贡区"
        },
        {
          "value": "810114",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "荃湾区"
        },
        {
          "value": "810115",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "屯门区"
        },
        {
          "value": "810116",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "元朗区"
        },
        {
          "value": "810117",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "葵青区"
        },
        {
          "value": "810118",
          "fathervalue": "810000",
          "fatherLabel": "香港特别行政区",
          "label": "离岛区"
        },
      ]
    },
    {
      "value": "820000",
      "label": "澳门特别行政区",
      "children": [
        {
          "value": "820101",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "花地玛堂区"
        },
        {
          "value": "820102",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "圣安多尼堂区"
        },
        {
          "value": "820103",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "大堂区"
        },
        {
          "value": "820104",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "望德堂区"
        },
        {
          "value": "820105",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "风顺堂区"
        },
        {
          "value": "820106",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "嘉模堂区"
        },
        {
          "value": "820107",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "圣方济各堂区"
        },
        {
          "value": "820108",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "路氹城"
        },
        {
          "value": "820109",
          "fathervalue": "820000",
          "fatherLabel": "澳门特别行政区",
          "label": "澳门新城"
        },
      ]
    },
    {
      "value": "830000",
      "label": "台湾省",
      "children": [
        {
          "value": "830100",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "台北市"
        },
        {
          "value": "830200",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "新北市"
        },
        {
          "value": "830300",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "桃园市"
        },
        {
          "value": "830400",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "台中市"
        },
        {
          "value": "830500",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "台南市"
        },
        {
          "value": "830600",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "高雄市"
        },
        {
          "value": "830700",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "基隆市"
        },
        {
          "value": "830800",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "新竹市"
        },
        {
          "value": "830900",
          "fathervalue": "830000",
          "fatherLabel": "台湾省",
          "label": "嘉义市"
        },
      ]
    },
  ];



  export  function  getSecondaryCities() {
    return secondaryCities;
  }
  
  