<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="账号" prop="username" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
          </lay-form-item>
          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
          </lay-form-item>

        </lay-form>
        <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
        </div>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          <template #delFlag="{ row }">
            <lay-switch :model-value="row.delFlag == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
              unswitch-text="禁用"></lay-switch>
          </template>


          <template #roleSign="{ row }">
            <span>管理员</span>
          </template>

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
          </template>
        </lay-table>



      </lay-panel>

    </lay-col>

  </lay-row>
  <lay-layer v-model="visible11" :shade="false" :area="['350px', '300px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="user" ref="layFormRef11" required :pane="true">
        <lay-form-item label="账户" prop="userName">
          <lay-input v-model="user.userName" :disabled="isDisabled"></lay-input>
        </lay-form-item>
        <lay-form-item label="密码" prop="passWord">
          <lay-input v-model="user.passWord" type="passWord">></lay-input>
        </lay-form-item>
      </lay-form>
    </div>
  </lay-layer>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { addUser, allAdminUser, disableUsers } from '../../api'

const search = reactive({
  key: "",
})


const loading = ref(false);
const dataSource = ref([]);

const selectedKeys = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "用户ID", width: "260px", key: "userId", align: "center" },
  { title: "账号", key: "userName", align: "center" },
  { title: "状态", key: "delFlag", align: "center", customSlot: "delFlag" },
  { title: "角色", key: "roleSign", align: "center", customSlot: "roleSign" },
  { title: "注册时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
]);


const getAllAdminUser = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key };
  allAdminUser(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}
//获取数据
getAllAdminUser();


const change = (page: any) => {
  getAllAdminUser();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
const changedelFlag = (row: any) => {
  console.log(row.userId)
  var data = { "key": row.userId, "id": row.delFlag };
  disableUsers(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllAdminUser();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}

//查询接口
const searchUser = () => {
  page.current=1;
  getAllAdminUser();
}



const user = reactive({
  userId: "",
  userName: "",
  passWord: ""
})


const updateUser = (row: any) => {
  user.userId = row.userId;
  user.userName = row.userName;
  user.passWord = "";
  isDisabled.value = true;
  visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const isDisabled = ref(false);

const changeVisible11 = () => {
  user.userId = "";
  user.userName = "";
  user.passWord = "";
  isDisabled.value = false;
  visible11.value = !visible11.value;
}

const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addUser(JSON.stringify(user)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllAdminUser();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false;


    }
  }
])


</script>

<style scoped></style>