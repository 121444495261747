<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="搜索" prop="key" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入标题"></lay-input>
          </lay-form-item>
          <lay-form-item label="时间排序" prop="type" mode="inline">
            <lay-select v-model="search.type">
              <lay-select-option value="1" label="全部"></lay-select-option>
              <lay-select-option value="2" label="升序"></lay-select-option>
              <lay-select-option value="3" label="降序"></lay-select-option>
            </lay-select>
          </lay-form-item>

          <lay-form-item label="地区" prop="keyword" mode="inline">
            <lay-cascader :options="options" v-model="search.keyword" :changeOnSelect="true" allow-clear
              placeholder="选择地区"></lay-cascader>
          </lay-form-item>


          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchFengLou">查询</lay-button>
          </lay-form-item>
        </lay-form>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          
          <template #type="{ row }">
            <lay-select v-model="row.type" placeholder="请选择">
              <lay-select-option :value="0" @click="changeType(row, row.type)" label="待审核"></lay-select-option>
              <lay-select-option :value="1" @click="changeType(row, row.type)" label="通过审核"></lay-select-option>
              <lay-select-option :value="2" @click="changeType(row, row.type)" label="审核失败"></lay-select-option>
            </lay-select>
          </template>

          <template v-slot:imgs="{ row }">
            <img v-if="row.imgs!=null && row.imgs!=''" :src="row.imgs.split(',')[0]"
              @click="signleImg(row.imgs.split(',')[0])" style="width:62.9px;height:80px" />
          </template>


        </lay-table>



      </lay-panel>
    </lay-col>

  </lay-row>

  <lay-layer v-model="visible11" :shade="false" :area="['500px', '450px']" :btn="action11">
    <div style="padding: 20px;">
      <lay-form :model="remark" ref="layFormRef11" > 
        
        <lay-form-item label="说明" prop="desc">
          <lay-textarea placeholder="请输入说明" v-model="remark"></lay-textarea>
        </lay-form-item>
      </lay-form>
    </div>
  </lay-layer>

</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'

import { getSecondaryCities } from "../../utils/secondaryCities";

import { allProcessFengLou ,reviewFengLou} from '../../api'


const search = reactive({
  key: "", 
  keyword: "",
  type: "0"

})

//地区联动
const options = getSecondaryCities();



const loading = ref(false);

const selectedKeys = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });
const dataSource = ref([]);

const columns = ref([
  { title: "图片", width: "100px", key: "imgs", align: "center", customSlot: "imgs" },
  { title: "标题", width: "150px", key: "title", align: "center", ellipsisTooltip: true, },
  { title: "价格", width: "80px", key: "price", align: "center" },
  { title: "年龄", width: "80px", key: "age", align: "center" },
  { title: "颜值", width: "80px", key: "appearance", align: "center" },
  { title: "服务项目", width: "80px", key: "serviceItems", align: "center" },
  { title: "地区", width: "120px", key: "area", align: "center" },
  { title: "地址", width: "120px", key: "address", align: "center" },
  { title: "手机", width: "80px", key: "phone", align: "center" },
  { title: "qq", width: "80px", key: "qq", align: "center" },
  { title: "微信", width: "80px", key: "weChat", align: "center" },
  { title: "飞机", width: "80px", key: "plain", align: "center" },
  { title: "简介", key: "summary", align: "center", ellipsisTooltip: true, },
  { title: "说明", key: "remark", align: "center" },
  { title: "审核状态", width: "120px", key: "type", align: "center", customSlot: "type" },
  { title: "账号", width: "120px", key: "userName", align: "center" },
  { title: "添加时间", width: "120px", key: "addTime", align: "center" }
]);




const getProcessFengLou = () => {
  var province = "";
  var city = "";
  if (search.keyword != null && search.keyword.includes("/")) {
    var provincelist = search.keyword.split("/")
    province = provincelist[0];
    city = provincelist[1];
  } else {
    province = search.keyword;
  }

  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key, "type": search.type, "province": province, "city": city };
  allProcessFengLou(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = [];
      var fengLouList = res.data;
      for (var i = 0; i < fengLouList.length; i++) {
        fengLouList[i].area = getProvince(fengLouList[i].province, fengLouList[i].city);
      }


      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })

}


getProcessFengLou();
const change = (page:any) => {
  getProcessFengLou();
}

const sortChange = (key:any, sort:any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}



//获取地区 
const getProvince = (province: any, city: any) => {
  var provinceStr = "";
  if (province != null) {
    for (var i = 0; i < options.length; i++) {
      if (options[i].value == province) {
        provinceStr = options[i].label;
        break;
      }
    }
  }
  if (city != null) {
    for (var i = 0; i < options.length; i++) {
      if (options[i].value == province) {
        var list = options[i].children;
        for (var j = 0; j < list.length; j++) {
          if (list[j].value == city) {
            provinceStr = provinceStr + "/" + list[j].label;
            break;
          }

        }


      }
    }
  }
   return provinceStr; 
}

//查询
const  searchFengLou=()=>{
  getProcessFengLou();
}

//查看图片

const signleImg = (imgur: any) => {
  layer.photos({
    imgList: [{ src: imgur }]
  })
}

//状态
var data:any;
const changeType = (row: any, value: any) => {
   data = { "key": value, "id": row.id };
  if(value==2){
    visible11.value = !visible11.value;

  }else{
    addReviewFengLou(JSON.stringify(data))
  }
 
}

const addReviewFengLou=(data:any)=>{

  reviewFengLou(data).then((res: any) => {
     
    if (res != "" && res.code == 0) {

      layer.msg("操作成功", { icon : 1, time: 1000})
      getProcessFengLou();
    } else {
      layer.msg(res.msg, { icon : 2, time: 1000})
    }
  })

}


const remark =ref("");

const visible11 = ref(false);

const action11 = ref([
    {
        text: "确认",
        callback: () => { 
          data.keyword=remark.value; 
          addReviewFengLou(JSON.stringify(data)) 
          visible11.value = !visible11.value;
        }
    },
    {
        text: "取消",
        callback: () => {
          visible11.value = !visible11.value;
        }
    }
])

</script>

 