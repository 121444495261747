<template>
  <lay-row space="10">
    <lay-col md="12">
      <lay-panel style="padding: 10px">
        <lay-field title="基本配置">

          <lay-form :model="model">
            <lay-form-item label="图片地址：" prop="photoUrl">
              <lay-input v-model="model.photoUrl"></lay-input>
            </lay-form-item>


            <lay-form-item label="强制更新：" prop="isforce">
              <lay-radio v-model="model.isforce" name="isforce" :value="0" label="否"></lay-radio>
              <lay-radio v-model="model.isforce" name="isforce" :value="1" label="是"></lay-radio>
            </lay-form-item>


            <lay-form-item label="app版本号：" prop="appVersion">
              <lay-input v-model="model.appVersion"></lay-input>
            </lay-form-item>

            <lay-form-item label="下载链接：" prop="downloadUrl">
              <lay-input v-model="model.downloadUrl"></lay-input>
            </lay-form-item>

            <lay-form-item label="图片水印：" prop="watermark">
              <lay-input v-model="model.watermark"></lay-input>
            </lay-form-item>
            <lay-form-item label=" 单文件大小：" prop="oneUploadSize">
              <lay-input v-model="model.oneUploadSize"></lay-input>
            </lay-form-item>

            <lay-form-item label="每次多少张：" prop="howManySheets">
              <lay-input v-model="model.howManySheets"></lay-input>
            </lay-form-item>

            <lay-form-item label="每天发布数量：" prop="releasesNum">
              <lay-input v-model="model.releasesNum"></lay-input>
            </lay-form-item>

            <lay-form-item label="飞机频道：" prop="airplane">
              <lay-input v-model="model.airplane"></lay-input>
            </lay-form-item>

            <lay-form-item label="TG客服：" prop="airplane">
              <lay-input v-model="model.ca"></lay-input>
            </lay-form-item>

            <lay-form-item label="推广地址：" prop="tgUrl">
              <lay-input v-model="model.tgUrl"></lay-input>
            </lay-form-item>
           

            <lay-form-item style="text-align: center;">
              <lay-button type="primary" @click="submitConfig">提交</lay-button>
            </lay-form-item>
          </lay-form>


        </lay-field>
      </lay-panel>
    </lay-col>
    <lay-col md="12">
      <lay-panel style="padding: 10px">
        <lay-field title="爬虫配置">

          <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
        </div>

          <lay-table  :height="'100%'" :columns="columns"  :data-source="dataSource"
          v-model:selected-keys="selectedKeys">
          <template #state="{ row }">
            <lay-select v-model="row.state" placeholder="请选择">
            <lay-select-option :value="1" @click="setCollectionState(row.id,1)" label="暂停"></lay-select-option>
            <lay-select-option :value="2" @click="setCollectionState(row.id,2)" label="正在采集"></lay-select-option> 
          </lay-select>
          </template> 

          <template #platform="{ row }">
            <span v-if="row.platform==3">楼凤天堂</span>
            <span v-else-if="row.platform==6">91青楼</span>
          </template> 
 
          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="updateCollection(row)">编辑</lay-button>
            <lay-button size="xs" type="primary" @click="updateUser(row)">删除</lay-button>
          </template>
        </lay-table>
        </lay-field> 
      </lay-panel>
    </lay-col>
  </lay-row>



  <lay-layer v-model="visible11" :shade="false" :area="['450px', '500px']" :btn="action11" title="添加采集/修改采集">
    <div style="padding: 20px;">
      <lay-form :model="collection" ref="layFormRef11" >
        <lay-form-item label="采集网站" prop="website">
          <lay-input v-model="collection.website" ></lay-input>
        </lay-form-item>

        <lay-form-item label="资源网站" prop="authorize">
          <lay-input v-model="collection.authorize" ></lay-input>
        </lay-form-item>

        <lay-form-item label="起始页" prop="startPage">
          <lay-input v-model="collection.startPage" ></lay-input>
        </lay-form-item>

        <lay-form-item label="结束页" prop="endPage">
          <lay-input v-model="collection.endPage" ></lay-input>
        </lay-form-item> 

        <lay-form-item label="平台" prop="platform">
          <lay-select v-model="collection.platform" placeholder="请选择">
            <lay-select-option :value="3" label="楼凤天堂"></lay-select-option>
            <lay-select-option :value="6" label="91青楼"></lay-select-option> 
          </lay-select>
        </lay-form-item>
     
      </lay-form>
    </div>
  </lay-layer>

</template>

<script  lang="ts" setup>
import { ref, reactive, toRefs } from 'vue'
import { layer } from '@layui/layer-vue'
import { addSysConfig, getSysConfig,allCollection,addCollection,updateCollectionState} from '../../api'

const visible11 = ref(false);

 
const selectedKeys = ref([]);

const dataSource = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "采集网站", key: "website", align: "center" },
  { title: "资源网站", key: "authorize", align: "center" },
  { title: "状态", key: "state", align: "center", customSlot: "state", width: "200px" },
  { title: "起始页", key: "startPage", align: "center"},
  { title: "结束页", key: "endPage", align: "center"  },
  { title: "平台", key: "platform", align: "center" , customSlot: "platform"},
  { title: "添加时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
]);


const getAllCollection = () => {
   
  allCollection().then((res: any) => {
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}

getAllCollection();

 


const collection = reactive({
  id:0,
  website: "",
  authorize: "",
  startPage: 1,
   endPage:1,
   platform:0,
})

const changeVisible11 = () => {
  collection.id=0,
  collection.website="",
  collection.authorize="",
  collection.startPage= 1,
  collection.endPage=1,
  collection.platform=0,
  visible11.value = !visible11.value;
}

const updateCollection = (row: any) => {
  collection.id=row.id;
  collection.website=row.website;
  collection.authorize=row.authorize;
  collection.startPage=row.startPage;
  collection.endPage=row.endPage;
  collection.platform=row.platform;
  visible11.value = !visible11.value;
}


const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addCollection(JSON.stringify(collection)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllCollection();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false; 
    }
  }
])



const setCollectionState=(id:any,state:any)=>{
  var data={'id':id,'key':state} 
  updateCollectionState(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllCollection();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })

}















let model = reactive({
  isforce: "0",
  photoUrl: "",
  appVersion: "",
  downloadUrl: "",
  oneUploadSize: "",
  howManySheets: 1,
  releasesNum: "",
  airplane: "",
  proclamation: "",
  tgUrl: "",
  watermark:"",
  ca:""

})




const submitConfig = () => {
  addSysConfig(JSON.stringify(model)).then((res: any) => {
    if (res != "" && res.code == 0) {
      layer.msg("操作成功", { time: 1000, icon: 1 })
    }
  })
};

const getConfigData = () => {
  getSysConfig().then((res: any) => {
    if (res != "" && res.code == 0) {
      model.isforce = res.data.isforce;
      model.photoUrl = res.data.photoUrl;
      model.appVersion = res.data.appVersion;
      model.downloadUrl = res.data.downloadUrl;
      model.oneUploadSize = res.data.oneUploadSize;
      model.releasesNum = res.data.releasesNum;
      model.airplane = res.data.airplane;
      model.proclamation = res.data.proclamation;
      model.howManySheets = res.data.howManySheets;
      model.tgUrl = res.data.tgUrl;
      model.watermark = res.data.watermark;
      model.ca = res.data.ca;  
    }
  })
};

getConfigData();

 
</script>