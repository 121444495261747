<template>
    <div class="center-text">

        <lay-panel>

            <lay-form :model="model2" :pane="true">
                <lay-form-item label="账户" prop="username">
                    <lay-input v-model="model2.username" disabled="true "></lay-input>
                </lay-form-item>
                <lay-form-item label="旧密码" prop="password">
                    <lay-input v-model="model2.password" type="password"></lay-input>
                </lay-form-item>

                <lay-form-item label="新密码" prop="password">
                    <lay-input v-model="model2.password" type="password"></lay-input>
                </lay-form-item>

                <lay-form-item label="确定密码" prop="password">
                    <lay-input v-model="model2.password" type="password"></lay-input>
                </lay-form-item>
                <lay-form-item>
                    <lay-button type="primary" @click="submit2">提交</lay-button>
                </lay-form-item>
            </lay-form>

        </lay-panel>

    </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'

const model2 = reactive({
    username: "admin",
    password: "admin",
})

const submit2 = function () {
    layer.open(`${JSON.stringify(model)}`);
};
</script>

<style >
.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    /* 100% of the viewport height */
}
</style>