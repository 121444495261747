import request from "@/utils/request";



export  function  login(data:any) {
    return request({
        url: 'sys/login',
        method: 'post', 
        data,
        headers: {
          'Content-Type': 'application/json'
        }
    })
}


export  function  addUser(data:any) {
    return request({
        url: 'sys/user/addUser',
        method: 'post', 
        data,
        headers: {
          'Content-Type': 'application/json'
        }
    })
}


export  function  allAdminUser(data:any) {
    return request({
        url: 'sys/user/allAdminUser',
        method: 'post', 
        data,
        headers: {
          'Content-Type': 'application/json'
        }
    })
}



export  function  disableUsers(data:any) {
    return request({
        url: 'sys/user/disableUsers',
        method: 'post', 
        data,
        headers: {
          'Content-Type': 'application/json'
        }
    })
}

export  function  allUser(data:any) {
  return request({
      url: 'sys/user/allUser',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  modifyUserInfo(data:any) {
  return request({
      url: 'sys/user/modifyUserInfo',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addFengLou(data:any) {
  return request({
      url: 'sys/fengLou/addFengLou',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  allFengLou(data:any) {
  return request({
      url: 'sys/fengLou/allFengLou',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  updateIsAssure(data:any) {
  return request({
      url: 'sys/fengLou/updateIsAssure',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  updateFengLouState(data:any) {
  return request({
      url: 'sys/fengLou/updateFengLouState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  delFengLou(data:any) {
  return request({
      url: 'sys/fengLou/delFengLou',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  reviewFengLou(data:any) {
  return request({
      url: 'sys/fengLou/reviewFengLou',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  allProcessFengLou(data:any) {
  return request({
      url: 'sys/fengLou/allProcessFengLou',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addSysConfig(data:any) {
  return request({
      url: 'sys/config/addSysConfig',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addPayInfo(data:any) {
  return request({
      url: 'sys/payInfo/addPayInfo',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  allPayInfo(data:any) {
  return request({
      url: 'sys/payInfo/allPayInfo',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  delPayInfo(data:any) {
  return request({
      url: 'sys/payInfo/delPayInfo',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  payInfoState(data:any) {
  return request({
      url: 'sys/payInfo/payInfoState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addPaymentChannel(data:any) {
  return request({
      url: 'sys/paymentChannel/addPaymentChannel',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allPaymentChannel(data:any) {
  return request({
      url: 'sys/paymentChannel/allPaymentChannel',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  delPaymentChannel(data:any) {
  return request({
      url: 'sys/paymentChannel/delPaymentChannel',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  paymentChannelState(data:any) {
  return request({
      url: 'sys/paymentChannel/paymentChannelState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  allPayLog(data:any) {
  return request({
      url: 'sys/payLog/allPayLog',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  getSysConfig() {
  return request({
      url: 'sys/config/getSysConfig',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allComplaint(data:any) {
  return request({
      url: '/sys/complaint/allComplaint',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  modifyStatus(data:any) {
  return request({
      url: '/sys/complaint/modifyStatus',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  delComplaint(data:any) {
  return request({
      url: '/sys/complaint/delComplaint',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  allStatistics() {
  return request({
      url: '/sys/statistics/allStatistics',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  setReptileSubmit(data:any) {
  return request({
      url: '/sys/config/setReptileSubmit',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allCollection() {
  return request({
      url: '/sys/config/allCollection',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  addCollection(data:any) {
  return request({
      url: '/sys/config/addCollection',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 
export  function  updateCollectionState(data:any) {
  return request({
      url: '/sys/config/updateCollectionState',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 




