<template>
    <lay-row space="10">
        <lay-col md="24">
            <lay-panel> 
                <lay-form :model="search" style="text-align: start"  size="sm">
                    <lay-form-item label="订单编号" prop="key" mode="inline">
                    <lay-input v-model="search.key" placeholder="请输入编号"></lay-input>
                    </lay-form-item> 
                    <lay-form-item label="状态" prop="state" mode="inline">
                    <lay-select v-model="search.state">
                        <lay-select-option value="0" label="全部"></lay-select-option>
                        <lay-select-option value="1" label="等待支付"></lay-select-option> 
                        <lay-select-option value="2" label="交易成功"></lay-select-option> 
                    </lay-select> 
                    
                </lay-form-item>  
                
                    <lay-form-item label="时间" prop="hobby" mode="inline">
                     
                        <lay-date-picker  v-model="search.rangeTime2" range type="datetime" :placeholder="['开始日期','结束日期']"></lay-date-picker> 
                        
                    </lay-form-item>
   
                    <lay-form-item mode="inline" style="text-align: right; ">
                    <lay-button type="primary" size="sm"  @click="submit2">查询</lay-button> 
                    </lay-form-item>

                </lay-form>
               
                <lay-table 
                    :page="page"  
                    :height="'100%'"
                    :columns="columns" 
                    :loading="loading" 
                    :data-source="dataSource" 
                    v-model:selected-keys="selectedKeys"  
                    @change="change"
                    @sortChange="sortChange"> 


                    <template v-slot:state="{ row }">
                      <span v-if="row.state==0">等待支付</span>
                      <span v-else-if="row.state==1">支付失败</span>
                      <span v-else-if="row.state==2">支付成功</span>
           
                    </template>
          <template v-slot:type="{ row }">
            <span v-if="row.type==0">支付宝</span>
            <span v-else-if="row.type==1">微信</span>
          </template>
          <template v-slot:platform="{ row }">
            <span v-if="row.platform==0">易安支付</span>
            <span v-else-if="row.platform==1">聚财支付</span>
          </template>
                </lay-table>



            </lay-panel>
        </lay-col>

    </lay-row>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import {layer} from '@layui/layer-vue'

import { allPayLog } from '../../api'




const search = reactive({
  key: "",
  state:"1", 
  rangeTime2:[]
  
})





const loading = ref(false);

    const selectedKeys = ref([]);

    const page = reactive({ current: 1, limit: 10, total: 0 });

    const columns = ref([ 
      { title:"订单编号", width: "150px", key:"orderid" ,align:"center"},
      { title:"金额", width: "150px", key:"amount",align:"center"},
      { title:"状态", width: "150px", key:"state",align:"center",customSlot:'state'},
      { title:"支付方式", width: "150px", key:"type",align:"center",customSlot:'type'},
      { title:"商户id", width: "80px", key:"memberid",align:"center" },
      { title:"平台名称", width: "80px", key:"platform",align:"center" ,customSlot:'platform'},
      { title:"账号", width: "80px", key:"userName",align:"center" },
      { title:"创建时间", width: "80px", key:"addTime",align:"center" },
      { title:"结束时间", width: "80px", key:"dateTime",align:"center"},   
    ]);



    const getallPayLog = () => { 
          loading.value = true;
          var data = { "page": page.current, "limit": page.limit, "key": search.key, "state": search.state};
          allPayLog(JSON.stringify(data)).then((res: any) => {
            loading.value = false;
            if (res != "" && res.code == 0) {
              dataSource.value=[];
          
              dataSource.value = res.data;
              page.total = res.count;
            } else {
              dataSource.value = [];
              page.total = 0;
            }
          })

}

getallPayLog();



    const change = (page:any) => {
  
      getallPayLog();
    }

    const sortChange = (key:any, sort:any) => {
      layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
    }

    const dataSource = ref([ ])
 



</script>

<style scoped>
 
</style>