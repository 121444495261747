<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm"> 
  
            <lay-form-item label="状态" prop="state" mode="inline">
              <lay-select v-model="search.state">
                <lay-select-option value="0" label="全部"></lay-select-option>
                <lay-select-option value="1" label="未处理"></lay-select-option>
                <lay-select-option value="2" label="已处理"></lay-select-option>
  
              </lay-select>
            </lay-form-item>
  
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item>
  
          </lay-form>
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
 
  
            <template v-slot:imgs="{ row }">
            <img :src="row.imgs.split(',')[0]"
              @click="signleImg(row.imgs.split(',')[0])" style="width:62.9px;height:80px" />
          </template>
  
          <template #state="{ row }">
            <lay-select v-model="row.state" placeholder="请选择">
              <lay-select-option :value="0" @click="changeState(row,row.state)" label="待处理"></lay-select-option>
              <lay-select-option :value="1" @click="changeState(row,row.state)" label="下架楼凤"></lay-select-option>
              <lay-select-option :value="2" @click="changeState(row,row.state)" label="不属实"></lay-select-option>
            </lay-select>
          </template>


          <template v-slot:operator="{ row }"> 
            <lay-button size="xs" type="danger" @click="deleteComplaint(row)">删除</lay-button>
          </template>


          
          </lay-table>
   
        </lay-panel>
      </lay-col>
  
    </lay-row> 

    
  <lay-layer v-model="visible11" :shade="false" :area="['400px', '280px']" :btn="action11" title="说明">
    <div style="padding: 20px;">
      <lay-form :model="remark" ref="layFormRef11" >  
        <lay-form-item label="说明" prop="desc">
          <lay-textarea placeholder="请输入说明" v-model="remark"></lay-textarea>
        </lay-form-item>
      </lay-form>
    </div>
  </lay-layer>

  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  
  import { allComplaint,modifyStatus,delComplaint } from '../../api'
  
  
  
  
  const search = reactive({ 
    state: "0", 
  })
  
  
  const loading = ref(false);
  
  const selectedKeys = ref([]);
  
  const dataSource = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([ 
    { title: "图片", key: "imgs", align: "center", customSlot: "imgs" },
    { title: "标题", key: "title", align: "center" },
    { title: "简介", key: "summary", align: "center"},
    { title: "状态", key: "state", align: "center", customSlot: "state", width: "150px" },
    { title: "投诉信息", key: "remark", align: "center" },
    { title: "发布者", key: "userName", align: "center" },
    { title: "投诉者", key: "complainant", align: "center" },
    { title: "注册时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
  ]);
  
  
  const getAllComplaint = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit,"state": search.state};
    allComplaint(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  
  getAllComplaint();
  
  const change = (page: any) => {
    getAllComplaint();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
   
  //搜索
  const searchUser=()=>{
    page.current=1; 
    getAllComplaint();
  }
  
  
//删除
  const deleteComplaint=(row: any)=>{

    data = {"id": row.id};
    delComplaint(JSON.stringify(data) ).then((res: any) => { 
     if (res != "" && res.code == 0) {
       layer.msg("操作成功", { icon : 1, time: 1000})
       getAllComplaint();
     } else {
       layer.msg(res.msg, { icon : 2, time: 1000})
     }
   })
  }


  //状态
var data:any;

const changeState = (row: any, value: any) => {
   data = { "state": value, "id": row.id,"key":row.loufengId };
  if(value==1){
    visible11.value = !visible11.value;

  }else{
    addReviewComplaint(JSON.stringify(data))
  }
 
}

const addReviewComplaint=(data:any)=>{

  modifyStatus(data).then((res: any) => {
     
    if (res != "" && res.code == 0) {

      layer.msg("操作成功", { icon : 1, time: 1000})
      getAllComplaint();
    } else {
      layer.msg(res.msg, { icon : 2, time: 1000})
    }
  })

}


const remark =ref("");

const visible11 = ref(false);

const action11 = ref([
    {
        text: "确认",
        callback: () => { 
          data.keyword=remark.value; 
          addReviewComplaint(JSON.stringify(data)) 
          visible11.value = !visible11.value;
        }
    },
    {
        text: "取消",
        callback: () => {
          visible11.value = !visible11.value;
        }
    }
])
   
  
  </script>
  
 