<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="搜索" prop="username" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入标题"></lay-input>
          </lay-form-item>
          <lay-form-item label="时间排序" prop="type" mode="inline">
            <lay-select v-model="search.type">
              <lay-select-option value="0" label="全部"></lay-select-option>
              <lay-select-option value="1" label="升序"></lay-select-option>
              <lay-select-option value="2" label="降序"></lay-select-option>
            </lay-select>
          </lay-form-item>

          <lay-form-item label="地区" prop="keyword" mode="inline">
            <lay-cascader :options="options" v-model="search.keyword" :changeOnSelect="true" allow-clear
              placeholder="选择地区"></lay-cascader>
          </lay-form-item>


          <lay-form-item label="状态" prop="state" mode="inline">
            <lay-select v-model="search.state">
              <lay-select-option value="0" label="全部"></lay-select-option>
              <lay-select-option value="1" label="正常"></lay-select-option>
              <lay-select-option value="2" label="置顶"></lay-select-option>
              <lay-select-option value="3" label="不展示"></lay-select-option>

            </lay-select>
          </lay-form-item>


          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchFengLou">查询</lay-button>
          </lay-form-item>
        </lay-form>
        <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" type="primary" @click="changeVisible11">新增</lay-button>
        </div>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

          <template #isAssure="{ row }">
            <lay-switch :model-value="row.isAssure == 1 ? true : false" @change="changeIsAssure(row)" onswitch-text="是"
              unswitch-text="否"></lay-switch>
          </template>

          <template #state="{ row }">
            <lay-select v-model="row.state" placeholder="请选择">
              <lay-select-option :value="0" @click="changeState(row, row.state)" label="正常"></lay-select-option>
              <lay-select-option :value="1" @click="changeState(row, row.state)" label="置顶"></lay-select-option>
              <lay-select-option :value="2" @click="changeState(row, row.state)" label="不展示"></lay-select-option>
            </lay-select>
          </template>

          <template v-slot:imgs="{ row }">
            <img :src="row.imgs.split(',')[0]"
              @click="signleImg(row.imgs.split(',')[0])" style="width:62.9px;height:80px" />
          </template>

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="editFengLou(row)">编辑</lay-button>
            <lay-button size="xs" type="danger" @click="deleteFengLou(row)">删除</lay-button>
          </template>
        </lay-table>
      </lay-panel>
    </lay-col>
  </lay-row>




  <lay-layer v-model="visible11" :shade="false" :area="['800px', '600px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="fengLou" ref="layFormRef11">
        <lay-form-item label="图片" prop="username" required>
          <lay-upload :beforeUpload="beforeUpload10" @done="getUploadFile2" :disabled="isDisabled"
            :disabledPreview="false" :multiple="false" acceptMime="image/*" size="1024"
            url="/common/imgUpload" field="files" :headers="headers">
            <template #preview>
              <div v-for="(image, index) in imgList" :key="index" class="image-container">
                <img :src="image" @click="signleImg(image)"
                  style="width:62.9px;height:80px" />
                <span class="delete-icon" @click="deleteImage(index)"> <lay-icon type="layui-icon-close" color="#fd0404"
                    size="20px"></lay-icon></span>
              </div>

            </template>
          </lay-upload>
        </lay-form-item>
        <lay-form-item label="标题" prop="title" required>
          <lay-input v-model="fengLou.title" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="价格" prop="price">
          <lay-input v-model="fengLou.price" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="年龄" prop="age">
          <lay-input v-model="fengLou.age" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="颜值" prop="appearance">
          <lay-input v-model="fengLou.appearance" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="服务项目" prop="serviceItems">
          <lay-input v-model="fengLou.serviceItems" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="地区" prop="password" required>
          <lay-cascader :options="options" v-model="fengLou.area" :changeOnSelect="true" allow-clear
            placeholder="选择地区"></lay-cascader>
        </lay-form-item>
        <lay-form-item label="地址" prop="address" required>
          <lay-input v-model="fengLou.address" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="号码" prop="phone">
          <lay-input v-model="fengLou.phone" type="text"></lay-input>
        </lay-form-item>

        <lay-form-item label="qq" prop="qq">
          <lay-input v-model="fengLou.qq" type="text">></lay-input>
        </lay-form-item>

        <lay-form-item label="微信" prop="weChat">
          <lay-input v-model="fengLou.weChat" type="text"></lay-input>
        </lay-form-item>

        <lay-form-item label="飞机" prop="plain">
          <lay-input v-model="fengLou.plain" type="text"></lay-input>
        </lay-form-item>
        <lay-form-item label="简介" prop="summary" required>
          <lay-textarea placeholder="请输入描述" v-model="fengLou.summary"></lay-textarea>
        </lay-form-item>
      </lay-form>
    </div>
  </lay-layer>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { getSecondaryCities } from "../../utils/secondaryCities";
import { addFengLou, allFengLou, updateIsAssure, updateFengLouState,delFengLou } from '../../api'
import { getToken } from '@/utils/auth'
const search = reactive({
  key: "",
  state: "0",
  keyword: "",
  type: "0"

})


const headers= reactive({
  'X-Token': getToken()
})


//地区联动
const options = getSecondaryCities();
const loading = ref(false);
const dataSource = ref([]);
const selectedKeys = ref([]);
const page = reactive({ current: 1, limit: 10, total: 0 });
const columns = ref([
  { title: "图片", width: "100px", key: "imgs", align: "center", customSlot: "imgs" },
  { title: "标题", width: "150px", key: "title", align: "center" , ellipsisTooltip: true,},
  { title: "价格", width: "80px", key: "price", align: "center" },
  { title: "年龄", width: "80px", key: "age", align: "center" },
  { title: "颜值", width: "80px", key: "appearance", align: "center" },
  { title: "服务项目", width: "80px", key: "serviceItems", align: "center" },
  { title: "地区", width: "120px", key: "area", align: "center" },
  { title: "手机", width: "80px", key: "phone", align: "center" },
  { title: "qq", width: "80px", key: "qq", align: "center" },
  { title: "微信", width: "80px", key: "weChat", align: "center" },
  { title: "飞机", width: "80px", key: "plain", align: "center" },
  { title: "简介", key: "summary", align: "center" , ellipsisTooltip: true,},
  { title: "官方担保", width: "80px", key: "isAssure", align: "center", customSlot: "isAssure" },
  { title: "状态", width: "120px", key: "state", align: "center", customSlot: "state" },
  { title: "更新时间", width: "120px", key: "updateTime", align: "center" },
  { title: "添加时间", width: "120px", key: "addTime", align: "center" },
  { title: "操作", width: "150px", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
]);


const getFengLou = () => {
  var province = "";
  var city = "";
  if (search.keyword!=null&&search.keyword.includes("/")) {
     var provincelist=search.keyword.split("/")
      province =provincelist[0] ;
      city =provincelist[1];
  } else {
    province = search.keyword;
  }

  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key, "state": search.state, "type": search.type, "province": province, "city": city };
  allFengLou(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value=[];
      var fengLouList= res.data;
       for(var i=0;i<fengLouList.length;i++){
          fengLouList[i].area=getProvince( fengLouList[i].province, fengLouList[i].city);
       }


      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })

}


//获取地区 
const getProvince = (province: any, city: any) => {
  var provinceStr = "";
  if (province != null) {
    for (var i = 0; i < options.length; i++) {
      if (options[i].value == province) {
        provinceStr = options[i].label;
        break;
      }
    }
  }
  if (city != null) {
    for (var i = 0; i < options.length; i++) {
      if (options[i].value == province) {
        var list = options[i].children;
        for (var j = 0; j < list.length; j++) {
          if (list[j].value == city) {
            provinceStr = provinceStr + "/" + list[j].label;
            break;
          }

        }


      }
    }
  }

   return provinceStr; 

}


getFengLou();

const change = () => {
  getFengLou();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}


//官方担保 
const changeIsAssure = (row: any) => {
  var data = { "key": row.isAssure, "id": row.id };

  updateIsAssure(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getFengLou();
      layer.msg("操作成功", { time: 1000, icon: 1 })
    }
  })

}

//状态
const changeState = (row: any, value: any) => {
  var data = { "key": value, "id": row.id };
  updateFengLouState(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getFengLou();
      layer.msg("操作成功", { time: 1000, icon: 1 })
    }
  })
}

//删除

const  deleteFengLou=(row: any)=>{


  layer.confirm("是否删除", 
        {
            btn: [
                {text:'确定', callback: (id:any) => { 


                  var data = { "id": row.id };

                    delFengLou(JSON.stringify(data)).then((res: any) => {
                      if (res != "" && res.code == 0) {
                        getFengLou();
                        layer.msg("操作成功", { time: 1000, icon: 1 })
                        layer.close(id);
                      
                      }
                    })

                    
                  }
                },
                {text:'取消', callback: (id:any) => { 
                   
                    layer.close(id); }
                }
            ]
        }
    );




}






//搜索
const searchFengLou = () => {
  page.current = 0;
  getFengLou();
}


//添加楼风

const fengLou = reactive({
  id: 0,
  title: "",
  price: "",
  age: "",
  appearance: "",
  serviceItems: "",
  area: "",
  phone: "",
  qq: "",
  weChat: "",
  plain: "",
  summary: "",
  updateTime: "",
  address: "",
  province: "",
  city: "",
  imgs: '',
})

//编辑
const editFengLou = (row: any) => {

  fengLou.id = row.id;
  fengLou.title = row.title;
  fengLou.price = row.price;
  fengLou.age = row.age;
  fengLou.appearance = row.appearance;
  fengLou.serviceItems = row.serviceItems;

  fengLou.phone = row.phone;
  fengLou.qq = row.qq;
  fengLou.weChat = row.weChat;
  fengLou.plain = row.plain;
  fengLou.summary = row.summary;
  fengLou.updateTime = row.updateTime;
  fengLou.address = row.address;
  fengLou.province = row.province;
  fengLou.city = row.city;
  fengLou.imgs = row.imgs;
  imgList.value = row.imgs.split(",");

  fengLou.area = row.province + "/" + row.city;

  visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const imgList = ref<string[]>([]);

const isDisabled = ref(false);

//查看图片

const signleImg = (imgur: any) => {
  layer.photos({
    imgList: [{ src: imgur }]
  })
}


const changeVisible11 = () => {
  visible11.value = !visible11.value;
}



const action11 = ref([
  {
    text: "确认",
    callback: () => {
      if (fengLou.area.length != 0) {
        var provincelist = fengLou.area.split("/");
        if (provincelist.length == 2) {
          fengLou.province = provincelist[0];
          fengLou.city = provincelist[1];
        } else {
          fengLou.province = provincelist[0];
        }

      }

      fengLou.imgs = imgList.value.join(',');
      addFengLou(JSON.stringify(fengLou)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getFengLou();
          visible11.value = false;
        }
      })

    }
  },
  {
    text: "取消",
    callback: () => {
      // layer.confirm("取消操作", { shade: false });
      visible11.value = false;
    }
  }
])

//上传文件回调方法
const getUploadFile2 = (res: any) => {
  res = JSON.parse(res.data);
  if (res.code == 0) {
    var imgs = res.data;
    for (var i = 0; i < imgs.length; i++) {
      imgList.value.push(imgs[i]);
    }
    if (imgList.value.length >= 5) {
      isDisabled.value = true;
    }
    layer.msg("上传成功", { time: 1000, icon: 1 })
  } else {
    layer.msg("上传异常", { icon: 3, time: 1000 })
  }

}



const beforeUpload10 = (file: any) => {
  if (imgList.value.length >= 5) {
    isDisabled.value = true;
    layer.msg("图片最多只能上传5张", { icon: 3, time: 1000 })
    return false;
  }
  return new Promise((resolver) => resolver(true));
}

//删除图片
const deleteImage = (index: any) => {
  imgList.value.splice(index, 1);
}

</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
}
</style>